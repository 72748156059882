<template>
    <!-- begin::Page loader -->
    <div class="w-100 h-100">
      <img alt="Logo" :src="logo" width="100" />
      <div class="spinner mt-5" v-bind:class="spinnerClass || 'spinner-primary'"></div>
    </div>
    <!-- end::Page Loader -->
  </template>
  
  <script>
  export default {
    name: "Loader",
    props: {
      logo: String,
      spinnerClass: String
    }
  };
  </script>
  